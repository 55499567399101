<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">security</v-icon>
        <v-toolbar-title>Change Password</v-toolbar-title>
      </v-app-bar>
    </v-card>

    <v-card>
      <v-card-text>
        <v-form ref="changePassword">
          <v-text-field
            v-model="changePasswordObj.currentPassword"
            label="Current Password"
            :rules="passwordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="changePasswordObj.newPassword"
            label="New Password"
            :rules="newPasswordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="changePasswordObj.confirmNewPassword"
            label="Confirm New Password"
            :rules="confirmationPasswordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn class="mt-5" dark color="primary" @click.prevent="updatePassword">Update Password</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DataService from "../services/dataServiceAdmin"
const dataService = new DataService();

export default {
  data() {
    return {
      passwordRules: [
        value => !!value || `Current password required`,
        value => (value || '').length >= 8 || `Minimum 8 characters not met`,
      ],
      newPasswordRules: [
        value => !!value || `New Password required`,
        value => (value || '').length >= 8 || `Minimum 8 characters not met`,
      ],
      confirmationPasswordRules: [
        value => !!value || `Confirmation new password required`,
        value => (value || '').length >= 8 || `Minimum 8 characters not met`,
        value => (value === this.changePasswordObj.newPassword) || `Confirmation new password does not match`,
      ],
      showPassword: false,
      changePasswordObj: {},
    };
  },
  methods: {
    async updatePassword() {
      if (await this.$refs.changePassword.validate()) {
        this.changePasswordObj.userId = this.$store.state.userProfile._id;
        
        dataService.updatePassword(this.changePasswordObj).then(() => {
          this.changePasswordObj = {};
          this.$store.state.showDialogMessage = true
          this.$store.state.dialogMessageTitle = "Password Changed Successfully"
          this.$store.state.dialogMessageText = "Your password has been changed successfully"
        })
      }
    },
  },
};
</script>